/*Login actions */
export const LOGIN = "LOGIN";
export const AUTH_FAILED = "AUTH_FAILED";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_LOADING = "AUTH_LOADING";
export const LOGOUT = "LOGOUT";
/*Application actions*/
export const GET_APPLICATIONS = "GET_APPLICATIONS";
export const SET_APPLICATION = "SET_APPLICATION";
export const ADD_SOKET_APPLICATION = "ADD_SOKET_APPLICATION"
export const UNSET_APPLICATION = "UNSET_APPLICATION";
export const CHANGE_APPLICATION_STATUS = "CHANGE_APPLICATION_STATUS";
export const DELETE_APPLICATION = "DELETE_APPLICATION";
export const DELETE_APPLICATIONS = "DELETE_APPLICATIONS";
export const ERROR_APPLICATION = "ERROR_APPLICATION";
export const ADD_APPLICATION = "ADD_APPLICATION";
export const LOADING_APPLICATIONS = "LOADING_APPLICATIONS";
export const TOGGLE_MODAL_APPLICATIONS = "TOGGLE_MODAL_APPLICATIONS";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const SET_MARRIAGE_OFFICE = "SET_MARRIAGE_OFFICE";
/*Admins actions */
export const DELETE_ADMIN = "DELETE_ADMIN";
export const ADD_ADMIN = "ADD_ADMIN";
export const CHANGE_ADMIN = "CHANGE_ADMIN";
export const GET_ADMINS = "GET_ADMINS";
/*Weddings actions*/
export const GET_WEDDINGS_DAYS = "GET_WEDDINGS_DAYS";
export const SET_WEDDING_DAYS = "SET_WEDDING_DAYS";
export const LOADING_WEDDINGS_DAYS = "LOADING_WEDDINGS_DAYS";
export const ERROR_WEDDINGS_DAYS = "ERROR_WEDDINGS_DAYS";